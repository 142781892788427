
import {computed, defineComponent, onMounted} from "vue";
import {LoadEntity} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import FormCase from "@/views/case/FormCase.vue";
import {IPolicy} from "@/core/entity/IPolicy";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {onUpdated} from "@vue/runtime-core";
import router from "@/router";
import store from "@/store";

export default defineComponent({
  name: "CreateCase",
  components: {FormCase},
  props: {
    insuredId: {type: String}
  },
  setup() {
    const policy = computed<IPolicy>(() => store.state.PolicyModule.policy)
    const user = computed(() => store.getters.currentUser)
    const createdCase = (caze) => {
      router.push({
        name: "caseOverview",
        params: {id: caze.id}
      });
    }
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Add Case',
        [
          {link: true, router: '/policies', text: 'Search Policy'},
          {link: true, router: '/policies/' + policy?.value?.id, text: 'View Policy'},
          {link: false, router: '', text: 'Add Case'}
        ]
      )
    })

    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Add Case',
        [
          {link: true, router: '/policies', text: 'Search Policy'},
          {link: true, router: '/policies/' + policy?.value?.id, text: 'View Policy'},
          {link: false, router: '', text: 'Add Case'}
        ]
      )
    })
    return {
      ...LoadEntity(Actions.LOAD_POLICY),
      policy,
      createdCase,
      user,
    }
  }
})
